import * as Ant from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import React from 'react';
interface Props {
  title: string;
  info?: string;
  fullsize?: boolean;
  children?: React.ReactNode;
}

const SectionEntry: React.FunctionComponent<Props> = (props) => (
  <Ant.Row style={{ marginBottom: 12 }} gutter={16}>
    <Ant.Col xs={24} md={props.fullsize ? 24 : 4}>
      <Ant.Typography.Text strong>
        {props.title}
        {props.info && (
          <Ant.Tooltip title={props.info}>
            <Ant.Typography.Link>
              <ExclamationCircleOutlined style={{ marginLeft: 8 }} />
            </Ant.Typography.Link>
          </Ant.Tooltip>
        )}
      </Ant.Typography.Text>
    </Ant.Col>
    <Ant.Col xs={24} md={props.fullsize ? 24 : 20}>
      {props.children}
    </Ant.Col>
  </Ant.Row>
);

export default SectionEntry;
