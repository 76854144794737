import { createApi } from '@reduxjs/toolkit/query/react';

import { SettingsProps } from '@parloa/core-e2e-monitoring-api';

import { axiosBaseQuery } from './base-query';

export const settingsApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v1/services/settings' }),
  reducerPath: 'settingsApi',
  tagTypes: ['Settings'],
  endpoints: (build) => ({
    getSettings: build.query<SettingsProps, void>({
      query: () => ({ url: '/', method: 'GET' }),
      providesTags: [{ type: 'Settings' }],
    }),
    updateSettings: build.mutation<SettingsProps, SettingsProps>({
      query: (data) => {
        if (data?.monitoringSettings.timeout < 5) {
          data.monitoringSettings.timeout = 5;
        }

        return {
          url: '/',
          method: 'PATCH',
          data: {
            ...data,
            monitoringSettings: {
              ...data.monitoringSettings,
              timeout: data?.monitoringSettings.timeout * 1000 * 60,
            },
          },
        };
      },
      invalidatesTags: [{ type: 'Settings' }],
    }),
  }),
});

export const useSettings = () => {
  const { data, ...rest } = useGetSettingsQuery();

  return { settings: data, ...rest };
};

export const { useLazyGetSettingsQuery, useGetSettingsQuery, useUpdateSettingsMutation } = settingsApi;
