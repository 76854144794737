import { Checkbox, Col, Row } from 'antd';

import React from 'react';

interface Props {
  value?: number[];
  onChange?(value: number[]): void;
}

const WeekDayChecker: React.FunctionComponent<Props> = ({ value, ...props }) => {
  const onChange = (weekdays: number[]) => {
    props.onChange(weekdays as number[]);
  };

  return (
    <Checkbox.Group style={{ width: '100%' }} value={value ?? [1, 2, 3, 4, 5, 6, 7]} onChange={onChange}>
      <Row>
        <Col span={6}>
          <Checkbox value={1}>MON</Checkbox>
        </Col>
        <Col span={6}>
          <Checkbox value={2}>TUE</Checkbox>
        </Col>
        <Col span={6}>
          <Checkbox value={3}>WED</Checkbox>
        </Col>
        <Col span={6}>
          <Checkbox value={4}>THU</Checkbox>
        </Col>
        <Col span={6}>
          <Checkbox value={5}>FRI</Checkbox>
        </Col>
        <Col span={6}>
          <Checkbox value={6}>SAT</Checkbox>
        </Col>
        <Col span={6}>
          <Checkbox value={7}>SUN</Checkbox>
        </Col>
      </Row>
    </Checkbox.Group>
  );
};

export default WeekDayChecker;
