import * as Ant from 'antd';
import { LaptopOutlined, HomeFilled, ControlOutlined, FireOutlined, LogoutOutlined } from '@ant-design/icons';
import React from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';

import { logout } from '../../data/thunks';
import { useAppDispatch } from '../../data/store';

interface Props {
  renderMenu?: boolean;
  title?: string;
}

const Header = (props: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  return (
    <Ant.Layout.Header style={{ display: 'flex' }}>
      <img style={{ padding: 16 }} alt="" src="assets/img/white.png" />
      <Ant.Divider
        style={{
          marginLeft: 16,
          marginRight: 32,
          height: '60%',
          alignSelf: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.65)',
        }}
        type="vertical"
      />
      {props.title && (
        <Ant.Typography.Title style={{ color: '#f0f2f5', alignSelf: 'center', marginBottom: 0 }} level={5}>
          {props.title}
        </Ant.Typography.Title>
      )}
      {props.renderMenu && (
        <>
          <Ant.Menu style={{ width: '100%' }} theme="dark" mode="horizontal" selectedKeys={[location.pathname]}>
            <Ant.Menu.Item icon={<HomeFilled />} key="/home">
              <NavLink to="/home">Overview</NavLink>
            </Ant.Menu.Item>
            <Ant.Menu.Item icon={<ControlOutlined key="/settings" />} key="/settings">
              <NavLink to="/settings">Settings</NavLink>
            </Ant.Menu.Item>
            <Ant.Menu.Item icon={<LaptopOutlined key="/monitored-applications" />} key="/monitored-applications">
              <NavLink to="/monitored-applications">Monitored Applications</NavLink>
            </Ant.Menu.Item>
            <Ant.Menu.Item icon={<FireOutlined key="/load-test" />} key="/load-test">
              <NavLink to="/load-test">Load Test</NavLink>
            </Ant.Menu.Item>
          </Ant.Menu>
          <div style={{ flex: 1 }} />
          <LogoutOutlined style={{ color: 'white', alignSelf: 'center' }} onClick={() => dispatch(logout())} />
        </>
      )}
    </Ant.Layout.Header>
  );
};

export default Header;
