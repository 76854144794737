import React from 'react';
import * as Ant from 'antd';
import { getCountryCallingCode, ParsedNumber, parseNumber, CountryCode } from 'libphonenumber-js';

import { CloseSquareOutlined } from '@ant-design/icons';

const countries: CountryCode[] = ['DE', 'AT', 'CH', 'PL', 'US'];

interface PhoneInputProps extends Omit<Ant.InputProps, 'onChange'> {
  index?: number;
  onDelete?(index: number): void;
  onChange?(value: string): void;
  validateFields?(): any;
}

const PhoneNumberInput: React.FC<PhoneInputProps> = ({ onDelete, index, ...props }) => {
  const [prefix, setPrefix] = React.useState(() => {
    try {
      return `+${getCountryCallingCode((parseNumber(props.value as string) as ParsedNumber).country)}`;
    } catch {
      return '+49';
    }
  });
  const [value, setValue] = React.useState(() => {
    try {
      return ((parseNumber(props.value as string) as ParsedNumber).phone as string) ?? '';
    } catch {
      return '';
    }
  });

  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    props.onChange(`${prefix}${event.target.value}`);
  };

  const onChangePrefix = (val: string) => {
    setPrefix(val);
    props.onChange(`${val}${value}`);
  };

  return (
    <Ant.Input
      {...props}
      value={value}
      defaultValue={value}
      addonBefore={
        <Ant.Select disabled={props.disabled} style={{ width: 80 }} defaultValue={prefix} onChange={onChangePrefix}>
          {countries.map((cc) => {
            const prefix = `+${getCountryCallingCode(cc).toString()}`;

            return (
              <Ant.Select.Option value={prefix} key={cc}>
                {`${prefix}`}
              </Ant.Select.Option>
            );
          })}
        </Ant.Select>
      }
      style={{ width: '100%' }}
      onChange={onChangeValue}
      suffix={
        onDelete && index !== undefined ? (
          <CloseSquareOutlined style={{ cursor: 'pointer' }} onClick={() => onDelete(index)} />
        ) : undefined
      }
    />
  );
};

export default PhoneNumberInput;
