import * as Ant from 'antd';
import {
  EditOutlined,
  PlusCircleOutlined,
  PhoneOutlined,
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { DateTime } from 'luxon';
import React, { CSSProperties, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ApplicationProps, CallResult } from '@parloa/core-e2e-monitoring-api';
import InformationsModal from '../../components/informations-modal';
import ParloaIcon from '../../components/parloa-icon';
import { selectApplications } from '../../data/slices/applications';
import { createApplication, fetchApplications, removeApplication, updateApplication } from '../../data/thunks';
import { colors } from '../../styles';

import CreateApplicationModal from './components/create-application-modal';
import EditApplicationModal from './components/edit-application-modal';
import { useAppDispatch } from '../../data/store';

const tagStyle: CSSProperties = {
  cursor: 'help',
  whiteSpace: 'nowrap',
  textTransform: 'capitalize',
};

const Indicator_ = styled.div<{ result: CallResult }>`
  width: 4px;
  height: 10px;
  transition: width, height 0.2s ease-out;
  position: relative;

  :hover {
    width: 8px;
    height: 20px;
  }

  margin-right: 2px;
  background-color: ${({ result }) =>
    result === 'error'
      ? colors.red1
      : result === 'interrupted'
      ? colors.sunYellow2
      : result === 'warning'
      ? colors.sunYellow1
      : result === 'success'
      ? colors.grassGreen1
      : colors.grey6};
`;

const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const getTag = (
  lastCallResult: ApplicationProps['lastCallResults'][0]['result'],
  lastTimeReachable: number | Date,
  small = false
) => {
  if (!lastCallResult) {
    return (
      <Ant.Tag style={tagStyle} color="gold" icon={<ExclamationCircleOutlined />}>
        {!small && lastTimeReachable ? 'unknown' : 'Never Called'}
      </Ant.Tag>
    );
  }

  switch (lastCallResult) {
    case 'success':
      return (
        <Ant.Tag style={tagStyle} color="green" icon={<CheckCircleOutlined />}>
          {!small && (lastCallResult ?? 'unknown')}
        </Ant.Tag>
      );
    case 'interrupted':
    case 'warning':
      return (
        <Ant.Tag style={tagStyle} color="gold" icon={<ExclamationCircleOutlined />}>
          {!small && (lastCallResult ?? 'unknown')}
        </Ant.Tag>
      );
    case 'error':
    default:
      return (
        <Ant.Tag style={tagStyle} color="red" icon={<CloseCircleOutlined />}>
          {!small && (lastCallResult ?? 'unknown')}
        </Ant.Tag>
      );
  }
};

const getActivatedTitle = (weekdays: ApplicationProps['weekdays'], timeframe: ApplicationProps['timeFrame']) => {
  let _days = '';
  if (weekdays.length !== 7) {
    weekdays.forEach((weekday, index) => {
      _days += `${!index ? '' : index === weekdays.length - 1 ? ' and ' : ', '}${days[weekday - 1]}s`;
    });
  }

  const result = [];

  result.push(
    <div>
      <span style={{ fontWeight: 700 }}>Activated!</span>
    </div>
  );
  result.push(
    <>
      {`Tested ${weekdays?.length === 7 ? `every day of the week${!timeframe ? '.' : ''}` : 'on '}`}
      <span style={{ fontStyle: 'italic' }}>{_days}</span>
    </>
  );

  if (timeframe) {
    result.push(<span> between </span>);
    result.push(
      <span style={{ fontStyle: 'italic' }}>{`${DateTime.fromFormat(timeframe.start, 'HH:mm:ss').toFormat(
        'HH:mm'
      )}h`}</span>
    );
    result.push(<span> and </span>);
    result.push(
      <span style={{ fontStyle: 'italic' }}>{`${DateTime.fromFormat(timeframe.end, 'HH:mm:ss').toFormat(
        'HH:mm'
      )}h.`}</span>
    );
  }

  return result;
};

const Applications: React.FunctionComponent = () => {
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState<ApplicationProps>();

  const dispatch = useAppDispatch();

  const applications = useSelector(selectApplications);

  React.useEffect(() => {
    dispatch(fetchApplications());
  }, [dispatch]);

  const onAddApplication = React.useCallback(() => {
    setCreateModalVisible(true);
  }, [setCreateModalVisible]);

  const onConfirmCreate = React.useCallback(
    (app: ApplicationProps) => {
      dispatch(createApplication(app));
      hideCreateModal();
    },
    [dispatch]
  );

  const onConfirmEdit = React.useCallback(
    (app: ApplicationProps) => {
      dispatch(updateApplication(app, selectedRecord._id.toString()));
      hideEditModal();
    },
    [dispatch, selectedRecord]
  );

  const hideCreateModal = () => {
    setCreateModalVisible(false);
  };

  const hideEditModal = () => {
    setEditModalVisible(false);
  };

  const onDelete = (id: string) => {
    dispatch(removeApplication(id));
    setEditModalVisible(false);
    setSelectedRecord(undefined);
  };

  return (
    <div>
      <CreateApplicationModal visible={createModalVisible} onConfirm={onConfirmCreate} onCancel={hideCreateModal} />
      <EditApplicationModal
        record={selectedRecord}
        visible={editModalVisible}
        onConfirm={onConfirmEdit}
        onCancel={hideEditModal}
        onDelete={onDelete}
      />
      <div style={{ whiteSpace: 'nowrap' }}>
        <Ant.Typography.Title level={1}>Checked Voice Bots</Ant.Typography.Title>
      </div>
      <Ant.Button
        style={{
          marginLeft: 'auto',
          marginBottom: 16,
        }}
        type="primary"
        size="large"
        icon={<PlusCircleOutlined />}
        onClick={onAddApplication}
      >
        Add Application
      </Ant.Button>
      <Ant.Table
        bordered
        dataSource={applications.map((app) => ({
          ...app,
          key: app._id.toString(),
        }))}
        pagination={false}
        columns={[
          {
            title: 'Application',
            width: 350,
            ellipsis: true,
            onCell: (record) => ({
              onClick: () => {
                setSelectedRecord(record);
                setEditModalVisible(true);
              },
            }),
            render: ({ name, active, weekdays, timeFrame }) => {
              return (
                <>
                  {active ? (
                    <Ant.Tooltip title={getActivatedTitle(weekdays, timeFrame)} placement="topLeft" arrowPointAtCenter>
                      <CheckCircleOutlined style={{ color: colors.brandBlue6 }} />
                    </Ant.Tooltip>
                  ) : (
                    <Ant.Tooltip
                      arrowPointAtCenter
                      title={<Ant.Typography.Text strong>Deactivated</Ant.Typography.Text>}
                      placement="topLeft"
                    >
                      <CloseCircleOutlined style={{ color: colors.grey6 }} />
                    </Ant.Tooltip>
                  )}
                  <Ant.Divider type="vertical" />
                  <Ant.Tooltip title={name}>
                    <Ant.Typography.Link>
                      {name}
                      <EditOutlined style={{ marginLeft: 8 }} />
                    </Ant.Typography.Link>
                  </Ant.Tooltip>
                </>
              );
            },
          },
          {
            title: 'Call Result',
            width: 138,
            responsive: ['md'],
            onCell: (record) => ({
              onClick: () => {
                InformationsModal.open(record);
              },
            }),
            render: (value: ApplicationProps) => {
              const { lastCallResults } = value;
              const lastCallResult = lastCallResults?.[0];
              const lastTimeReachable = lastCallResults?.find(({ result }) => result === 'success')?.date;

              const indexArray = [];

              for (let i = 0; i < 50; i++) {
                indexArray.push(i);
              }

              return (
                <Ant.Tooltip
                  overlayInnerStyle={{
                    width: 320,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  title={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: 20,
                      }}
                    >
                      {indexArray.reverse().map((index) => {
                        const result = lastCallResults[index];
                        return (
                          <Ant.Tooltip
                            overlayInnerStyle={{ width: 600 }}
                            key={index}
                            title={
                              result ? (
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Ant.Typography.Text strong>Date:</Ant.Typography.Text>
                                      </td>
                                      <td>
                                        {DateTime.fromMillis(result.date as number).toFormat('dd LLL yyyy - HH:mm:ss')}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Ant.Typography.Text strong>Call-Result:</Ant.Typography.Text>
                                      </td>
                                      <td>{result.result}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Ant.Typography.Text strong>Connection-Result:</Ant.Typography.Text>
                                      </td>
                                      <td>{result.connectionResult}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Ant.Typography.Text strong>Recognized Welcome Message:</Ant.Typography.Text>
                                      </td>
                                      <td>{result.transcribedWelcomeMessage}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Ant.Typography.Text strong>Recognized Response Message:</Ant.Typography.Text>
                                      </td>
                                      <td>{result.transcribedResponse}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              ) : (
                                'no data'
                              )
                            }
                          >
                            <div
                              style={{
                                width: '4px',
                                height: '10px',
                                transition: 'width, height 0.2s ease-out',
                                position: 'relative',
                                marginRight: '2px',
                                backgroundColor: `${
                                  result?.result === 'error'
                                    ? colors.red1
                                    : result?.result === 'interrupted'
                                    ? colors.sunYellow2
                                    : result?.result === 'warning'
                                    ? colors.sunYellow1
                                    : result?.result === 'success'
                                    ? colors.grassGreen1
                                    : colors.grey6
                                }`,
                              }}
                            />
                          </Ant.Tooltip>
                        );
                      })}
                    </div>
                  }
                >
                  {getTag(lastCallResult?.result, lastTimeReachable)}
                </Ant.Tooltip>
              );
            },
          },
          {
            title: 'Call Result',
            width: 80,
            responsive: ['xs'],
            onCell: (record) => ({
              onClick: () => {
                InformationsModal.open(record);
              },
            }),
            render: (value: ApplicationProps) => {
              const { lastCallResults } = value;
              const lastCallResult = lastCallResults?.[0];
              const lastTimeReachable = lastCallResults?.find(({ result }) => result === 'success')?.date;

              return (
                <Ant.Tooltip
                  title={
                    lastTimeReachable
                      ? `Last time reachable: ${DateTime.fromMillis(lastTimeReachable as number).toLocaleString(
                          DateTime.DATETIME_SHORT
                        )}`
                      : 'never called'
                  }
                >
                  {getTag(lastCallResult?.result, lastTimeReachable, true)}
                </Ant.Tooltip>
              );
            },
          },
          {
            responsive: ['md'],
            title: 'Tel. Number',
            dataIndex: 'number',
            ellipsis: true,
            width: 190,
            render: (telephoneNumber) => (
              <Ant.Typography.Link href={`tel:${telephoneNumber}`}>
                <PhoneOutlined style={{ marginRight: '8px' }} />
                <Ant.Tooltip title={telephoneNumber}>{telephoneNumber}</Ant.Tooltip>
              </Ant.Typography.Link>
            ),
          },
          {
            responsive: ['md'],
            title: 'Description',
            ellipsis: true,
            render: ({ description, parloaUrl, tenant }) => (
              <div style={{ display: ' flex' }}>
                <Ant.Tooltip title={description} placement="topLeft">
                  <div
                    style={{
                      flex: 1,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {description}
                  </div>
                </Ant.Tooltip>
                {parloaUrl && (
                  <>
                    <Ant.Divider type="vertical" />
                    <Ant.Tooltip title={`Open in Parloa${tenant ? `. Be sure to select "${tenant}" as tenant` : ''}`}>
                      <a href={parloaUrl} target="_blank" rel="noreferrer">
                        <Ant.Typography.Link>
                          <span style={{ marginLeft: 'auto', marginBottom: 16 }}>
                            <ParloaIcon />
                          </span>
                        </Ant.Typography.Link>
                      </a>
                    </Ant.Tooltip>
                  </>
                )}
              </div>
            ),
          },
          {
            responsive: ['md'],
            title: 'Tenant',
            ellipsis: true,
            dataIndex: 'tenant',
            width: '10%',
            render: (tenant) => <Ant.Tooltip title={tenant}>{tenant}</Ant.Tooltip>,
          },
        ]}
      />
    </div>
  );
};

export default Applications;
