import * as Ant from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { LoginOutlined, WarningOutlined } from '@ant-design/icons';
import React, { KeyboardEvent } from 'react';

import Header from '../../components/header';
import Footer from '../../components/footer';
import { login } from '../../data/thunks';
import { useAppDispatch } from '../../data/store';

const Forbidden: React.FunctionComponent = () => {
  const [form] = useForm();
  const [modalVisible, setModalVisible] = React.useState(false);

  const dispatch = useAppDispatch();

  const onOk = async () => {
    try {
      await form.validateFields();

      const { password, email } = form.getFieldsValue();
      dispatch(login({ email, password }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Ant.Layout>
        <Header title="Parloa End 2 End Monitoring Tool" />
        <Ant.Layout.Content style={{ padding: 24, justifyContent: 'center', display: 'flex' }}>
          <Ant.Card
            style={{ width: 400, marginTop: 'auto', marginBottom: 'auto' }}
            bodyStyle={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
          >
            <Ant.Typography.Title style={{ color: '#262626' }}>
              <WarningOutlined /> Forbidden <WarningOutlined />
            </Ant.Typography.Title>
            <Ant.Typography.Text>
              You are currently not allowed to use the tool. Please try to sign in with your parloa account.
            </Ant.Typography.Text>
            <div style={{ marginTop: 8 }}>
              <Ant.Typography.Link
                onClick={() => {
                  setModalVisible(true);
                }}
              >
                <LoginOutlined /> Sign-In
              </Ant.Typography.Link>
            </div>
          </Ant.Card>
        </Ant.Layout.Content>
        <Footer />
      </Ant.Layout>
      <Ant.Modal
        title="Sign-In with your parloa credentials"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        onOk={onOk}
        destroyOnClose
      >
        <Ant.Form
          labelCol={{ span: 4 }}
          form={form}
          onKeyPress={(event: KeyboardEvent) => {
            if (event.code === 'Enter') {
              onOk();
            }
          }}
        >
          <Ant.Form.Item label="E-Mail" name="email" required rules={[{ required: true }]}>
            <Ant.Input placeholder="user@parloa.com" />
          </Ant.Form.Item>
          <Ant.Form.Item label="Password" name="password" required rules={[{ required: true }]}>
            <Ant.Input.Password placeholder="********" />
          </Ant.Form.Item>
        </Ant.Form>
      </Ant.Modal>
    </>
  );
};
export default Forbidden;
