import axios from "axios";
import { message } from "antd";

export const postToken = async (accessToken: string) => {
  try {
    const { data } = await axios.post<{ token: { accessToken: string } }>(
      `/api/v1/auth/token`,
      { accessToken }
    );

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Token is still valid
      if (error.response.status === 422) {
        return {
          token: { accessToken },
        };
      } else {
        message.error({ content: error.message });
      }
    }
  }
};

export const postSignin = async (email: string, password: string) => {
  try {
    const { data } = await axios.post(`/api/v1/auth/signin`, {
      email,
      password,
    });

    return data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const content =
        error.response.status === 403
          ? "You are not allowed to use the monitoring tool."
          : error.response?.data?.message ?? error.message;
      message.error({ content });
    }
  }
};

export const postSignout = async () => {
  try {
    const { data } = await axios.post(`/api/v1/auth/signout`);

    return data;
  } catch (error) {
    console.log(error);

    if (axios.isAxiosError(error)) {
      message.error({ content: error.message });
    }
  }
};
