import * as Ant from 'antd';
import { CheckCircleFilled, CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

import { ApplicationProps } from '@parloa/core-e2e-monitoring-api';

const open = (application: ApplicationProps) => {
  return Ant.Modal.info({
    icon: <InfoCircleOutlined />,
    title: application.name,
    content: (
      <Ant.List>
        <Ant.List.Item>
          <Ant.List.Item.Meta title={'Number'} description={application.number} />
        </Ant.List.Item>
        <Ant.List.Item>
          <Ant.List.Item.Meta title={'Tenant'} description={application.tenant} />
        </Ant.List.Item>
        <Ant.List.Item>
          <Ant.List.Item.Meta title={'Description'} description={application.description} />
        </Ant.List.Item>
        <Ant.List.Item>
          <Ant.List.Item.Meta
            title={'Last received welcome message'}
            description={application.lastCallResults?.[0]?.transcribedWelcomeMessage ?? '- no message -'}
          />
        </Ant.List.Item>
        <Ant.List.Item>
          <Ant.List.Item.Meta
            title={'Last received response message'}
            description={application.lastCallResults?.[0].transcribedResponse ?? '- no message -'}
          />
        </Ant.List.Item>
        <Ant.List.Item>
          <Ant.List.Item.Meta
            title={'Is actively tested?'}
            description={
              application.active ? (
                <CheckCircleFilled style={{ color: 'green' }} />
              ) : (
                <CloseCircleFilled style={{ color: 'red' }} />
              )
            }
          />
        </Ant.List.Item>
      </Ant.List>
    ),
  });
};
const ex = { open };
export default ex;
