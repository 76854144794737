import { createApi } from '@reduxjs/toolkit/query/react';
import { LoadTestProps } from '@parloa/core-e2e-monitoring-api';
import { axiosBaseQuery } from './base-query';

export interface LoadTestRequestBody {
  callCount: number;
  destination: string;
  delay: number;
  botNumbers: string[];
  useDefaultBots: boolean;
  duration: number;
  connectionService: 'twilio' | 'tenios';
}

export const loadTestApi = createApi({
  baseQuery: axiosBaseQuery({ baseUrl: '/api/v1/' }),
  reducerPath: 'loadTestApi',
  tagTypes: ['Load-Tests'],
  endpoints: (build) => ({
    getLoadTests: build.query<LoadTestProps[], void>({
      query: () => ({ url: `load-tests`, method: 'GET' }),
      providesTags: [{ type: 'Load-Tests' }],
    }),
    getLoadTest: build.query<LoadTestProps, string>({
      query: (callerId) => ({ url: `load-tests/${callerId}`, method: 'GET' }),
    }),
    deleteLoadTest: build.mutation<LoadTestProps, string>({
      query: (callerId) => ({
        url: `load-tests/${callerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Load-Tests' }],
    }),
    executeLoadTest: build.mutation<undefined, LoadTestRequestBody>({
      query: (data) => ({ url: `load-tests`, data, method: 'POST' }),
    }),
  }),
});

export const { useGetLoadTestQuery, useGetLoadTestsQuery, useDeleteLoadTestMutation, useExecuteLoadTestMutation } =
  loadTestApi;
