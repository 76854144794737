import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadTestProps } from '@parloa/core-e2e-monitoring-api';

import { RootState } from '../store';

interface State {
  canUseDefaultBots: boolean;
  executedLoadTest: LoadTestProps;
  pending: boolean;
}

const initialState: State = {
  canUseDefaultBots: undefined,
  executedLoadTest: undefined,
  pending: false,
};

export const loadTestSlice = createSlice({
  name: 'load-test',
  initialState,
  reducers: {
    setLoadTest: (state, action: PayloadAction<State>) => {
      state.canUseDefaultBots = action.payload.canUseDefaultBots;
    },
    setExecutedLoadTest: (state, action: PayloadAction<LoadTestProps>) => {
      state.executedLoadTest = action.payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(fetchLoadTest.pending, (state) => {
  //       state.pending = true;
  //     })
  //     .addCase(fetchLoadTest.fulfilled, (state, action) => {
  //       state.pending = false;
  //       state.executedLoadTest = action.payload;
  //     })
  //     .addCase(fetchLoadTest.rejected, (state, action) => {
  //       state.pending = false;
  //       state.executedLoadTest = undefined;
  //     });
  //   builder
  //     .addCase(checkForDefaultBots.pending, (state) => {
  //       state.pending = true;
  //     })
  //     .addCase(checkForDefaultBots.fulfilled, (state, action) => {
  //       state.pending = false;
  //       state.canUseDefaultBots = action.payload;
  //     })
  //     .addCase(checkForDefaultBots.rejected, (state, action) => {
  //       state.pending = false;
  //       state.canUseDefaultBots = false;
  //     });
  // },
});

export const loadTestActions = loadTestSlice.actions;

export const selectLoadTest = (state: RootState) => state.loadTestFeature;

export default loadTestSlice.reducer;
