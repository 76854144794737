import * as Ant from 'antd';
import React from 'react';
import { StaticRouterProps } from 'react-router';

interface Props {
  staticContext?: StaticRouterProps['context'];
}

const NotFound: React.FunctionComponent<Props> = ({ staticContext = {} }) => {
  staticContext.statusCode = 404;
  return (
    <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center' }}>
      <div style={{ flexDirection: 'column', display: 'flex' }}>
        <Ant.Typography.Title style={{ margin: '24px 0 24px 0' }}>404</Ant.Typography.Title>
        <img alt="" src="https://media.giphy.com/media/VwoJkTfZAUBSU/source.gif" />
      </div>
    </div>
  );
};

export default NotFound;
