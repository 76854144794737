import { Modal, Form, Card, Input, InputNumber, Button, Tooltip } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';

import { SettingsProps } from '@parloa/core-e2e-monitoring-api';

import { ExclamationCircleOutlined, PlusCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import { useGetSettingsQuery, useUpdateSettingsMutation } from '../../data/api/settings';

import PhoneNumberInputFormItem from '../../components/phone-number-form-item';

export type TabKey = 'load-test' | 'monitoring';

const localeMapping = {
  de: '🇩🇪 German',
  en: '🇺🇸 English',
  pl: '🇵🇱 Polish',
};

const tabList = [
  { key: 'monitoring', tab: 'Monitoring' },
  { key: 'load-test', tab: 'Load Test' },
];

const Settings: React.FunctionComponent = () => {
  const { data } = useGetSettingsQuery(null);
  const [updateSettings] = useUpdateSettingsMutation();

  const [tabKey, setTabKey] = React.useState<TabKey>('monitoring');
  const [botSettingsDisabled, setBotSettingsDisabled] = React.useState<boolean[]>([]);
  const [miscSettingsDisabled, setMiscSettingsDisabled] = React.useState(true);
  const [disableLoadTesting, setDiableEditLoadTesting] = React.useState(true);

  const editing = botSettingsDisabled.some((disabled) => !disabled) || !miscSettingsDisabled || !disableLoadTesting;

  const [form] = useForm<SettingsProps>();

  const { monitoringSettings, ...rest } = data ?? {};

  React.useEffect(() => {
    if (data) {
      form.setFieldsValue({
        monitoringSettings: {
          ...monitoringSettings,
          timeout: (monitoringSettings.timeout ?? 20) / (60 * 1000),
        },
        ...rest,
      });

      setBotSettingsDisabled(monitoringSettings.configurations.map(() => true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, data]);

  const save = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      updateSettings({
        ...data,
        ...values,
        monitoringSettings: { ...monitoringSettings, ...values.monitoringSettings },
      });
    } catch {
      // nothing to do here
    }
  };

  const onTabChange = async (key: string) => {
    if (editing) {
      Modal.confirm({
        title: 'You have unsaved changes. You want to save these?',
        afterClose: () => {
          setBotSettingsDisabled(botSettingsDisabled.map(() => true));
          setDiableEditLoadTesting(true);
          setMiscSettingsDisabled(true);
          setTabKey(key as TabKey);
        },
      });
    } else {
      setTabKey(key as TabKey);
    }
  };

  return (
    <Form form={form} labelCol={{ span: 8 }} layout="horizontal">
      <Card tabList={tabList} defaultActiveTabKey={tabKey} onTabChange={onTabChange}>
        {tabKey === 'monitoring' && (
          <>
            <Card title="Bot Configurations" type="inner">
              <Form.List name={['monitoringSettings', 'configurations']}>
                {(fields) =>
                  fields.map((field, idx) => (
                    <Card
                      key={idx}
                      type="inner"
                      style={{ marginBottom: 16 }}
                      title={
                        <>
                          {
                            localeMapping[
                              form.getFieldValue('monitoringSettings').configurations[idx].locale as 'de' | 'en' | 'pl'
                            ]
                          }
                        </>
                      }
                      actions={[
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            padding: '0 16px',
                            gap: 16,
                          }}
                        >
                          <Button
                            disabled={editing && botSettingsDisabled.indexOf(false) !== idx}
                            style={{ justifySelf: 'flex-end' }}
                            type="primary"
                            icon={botSettingsDisabled[idx] && <EditOutlined />}
                            onClick={() => {
                              botSettingsDisabled[idx] = !botSettingsDisabled[idx];

                              if (botSettingsDisabled[idx]) {
                                save();
                              }

                              setBotSettingsDisabled([...botSettingsDisabled]);
                            }}
                          >
                            {botSettingsDisabled[idx] ? 'Edit' : 'Save'}
                          </Button>
                          {!botSettingsDisabled[idx] && (
                            <Button
                              style={{ justifySelf: 'flex-end' }}
                              icon={<EditOutlined />}
                              onClick={() => {
                                botSettingsDisabled[idx] = true;

                                setBotSettingsDisabled([...botSettingsDisabled]);
                              }}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>,
                      ]}
                    >
                      <Form.Item {...field} key={`sip-${field.key}`} name={[field.name, 'sipUri']} label="SIP-URI">
                        <Input
                          disabled={botSettingsDisabled[idx]}
                          placeholder="monitoring@sip.parloa.com"
                          addonBefore="sip:"
                        />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        key={`welcome-${field.key}`}
                        name={[field.name, 'welcomeMessage']}
                        label="Welcome Message Check"
                      >
                        <Input disabled={botSettingsDisabled[idx]} />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        key={`nlu-${field.key}`}
                        name={[field.name, 'nluMessage']}
                        label="NLU Check"
                      >
                        <Input disabled={botSettingsDisabled[idx]} />
                      </Form.Item>
                    </Card>
                  ))
                }
              </Form.List>
            </Card>
            <Card
              title="Misc"
              type="inner"
              style={{ marginTop: 16 }}
              actions={[
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    padding: '0 16px',
                  }}
                >
                  <Button
                    disabled={editing && miscSettingsDisabled}
                    // style={{ justifySelf: 'flex-end' }}
                    type="primary"
                    icon={miscSettingsDisabled && <EditOutlined />}
                    onClick={() => {
                      if (!miscSettingsDisabled) {
                        save();
                      }

                      setMiscSettingsDisabled(!miscSettingsDisabled);
                    }}
                  >
                    {miscSettingsDisabled ? 'Edit' : 'Save'}
                  </Button>
                  {!miscSettingsDisabled && (
                    <Button
                      style={{ justifySelf: 'flex-end' }}
                      icon={<EditOutlined />}
                      onClick={() => {
                        setMiscSettingsDisabled(true);
                      }}
                    >
                      Cancel
                    </Button>
                  )}
                </div>,
              ]}
            >
              <Form.Item
                label={
                  <span>
                    Caller Id
                    <Tooltip title="The number the tested bot will see as caller">
                      <ExclamationCircleOutlined style={{ marginLeft: 4 }} />
                    </Tooltip>
                  </span>
                }
                name={['monitoringSettings', 'callerId']}
                required
                rules={[{ required: true }]}
              >
                <Input disabled={miscSettingsDisabled} />
              </Form.Item>
              <Form.Item
                label="Timeout between calls(min)"
                name={['monitoringSettings', 'timeout']}
                required
                rules={[{ required: true }]}
              >
                <InputNumber disabled={miscSettingsDisabled} min={5} max={120} />
              </Form.Item>
              <Form.Item label="Slack Bot URL - Alerting" name={['monitoringSettings', 'slackBotUrl']}>
                <Input disabled={miscSettingsDisabled} />
              </Form.Item>
            </Card>
          </>
        )}
        {tabKey === 'load-test' && (
          <Card
            title="Default Bots"
            type="inner"
            actions={[
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  padding: '0 16px',
                  gap: 16,
                }}
              >
                <Button
                  icon={disableLoadTesting && <EditOutlined />}
                  type="primary"
                  onClick={() => {
                    console.log(disableLoadTesting);
                    if (!disableLoadTesting) {
                      save();
                    }

                    setDiableEditLoadTesting(!disableLoadTesting);
                  }}
                >
                  {disableLoadTesting ? 'Edit' : 'Save'}
                </Button>

                {!disableLoadTesting && (
                  <Button
                    style={{ justifySelf: 'flex-end' }}
                    icon={<EditOutlined />}
                    onClick={() => setDiableEditLoadTesting(!disableLoadTesting)}
                  >
                    Cancel
                  </Button>
                )}
              </div>,
            ]}
          >
            <Card title="Twilio" type="inner" style={{ marginBottom: 16 }}>
              <Form.List name={['defaultLoadTestingBots', 'twilio']}>
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map((field, index) => (
                      <Form.Item {...field} name={index}>
                        <Input
                          disabled={disableLoadTesting}
                          suffix={<DeleteOutlined onClick={() => remove(index)} />}
                        />
                      </Form.Item>
                    ))}
                    <Button disabled={disableLoadTesting} icon={<PlusCircleOutlined />} ghost onClick={() => add('')}>
                      Add Number
                    </Button>
                  </>
                )}
              </Form.List>
            </Card>
            <Card title="Tenios" type="inner">
              <Form.List name={['defaultLoadTestingBots', 'tenios']}>
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map((field, index) => (
                      <PhoneNumberInputFormItem
                        disabled={disableLoadTesting}
                        key={`load-testing-${field.key}`}
                        field={field}
                        index={index}
                        onDelete={remove}
                      />
                    ))}

                    <Button disabled={disableLoadTesting} icon={<PlusCircleOutlined />} ghost onClick={() => add('')}>
                      Add Number
                    </Button>
                  </>
                )}
              </Form.List>
            </Card>
          </Card>
        )}
      </Card>
    </Form>
  );
};

export default Settings;
