import { Dispatch } from '@reduxjs/toolkit';
import { ApplicationProps } from '@parloa/core-e2e-monitoring-api';

import { getApplications, postApplication, deleteApplication, patchApplication } from '../api/applications';
import { applicationsActions } from '../slices/applications';

export const fetchApplications = () => async (dispatch: Dispatch) => {
  dispatch(applicationsActions.setPending(true));

  const applications: ApplicationProps[] = await getApplications();
  if (applications) {
    dispatch(applicationsActions.setApplications({ applications }));
  } else {
    dispatch(applicationsActions.setPending(false));
  }
};

export const createApplication = (app: ApplicationProps) => async (dispatch: Dispatch) => {
  dispatch(applicationsActions.setPending(true));

  await postApplication(app);

  fetchApplications()(dispatch);
};

export const updateApplication = (app: ApplicationProps, id: string) => async (dispatch: Dispatch) => {
  dispatch(applicationsActions.setPending(true));

  await patchApplication(app, id);

  fetchApplications()(dispatch);
};

export const removeApplication = (id: string) => async (dispatch: Dispatch) => {
  dispatch(applicationsActions.setPending(true));

  await deleteApplication(id);

  fetchApplications()(dispatch);
};
