import { Dispatch } from "@reduxjs/toolkit";
import { message } from "antd";
import axios, { AxiosError } from "axios";

import { postSignin, postSignout, postToken } from "../api/auth";
import { authActions } from "../slices/auth";

export const login =
  (payload: { email: string; password: string }) =>
  async (dispatch: Dispatch) => {
    try {
      const { token } = await postSignin(payload.email, payload.password);

      dispatch(authActions.setAuthenticated({ authenticated: true }));
      dispatch(
        authActions.setAccessToken({
          accessToken: token.accessToken,
        })
      );
    } catch (error) {
      dispatch(authActions.setAuthenticated({ authenticated: false }));
    }
  };

export const logout = () => async (dispatch: Dispatch) => {
  try {
    await postSignout();

    dispatch(authActions.setAuthenticated({ authenticated: false }));
    dispatch(
      authActions.setAccessToken({
        accessToken: undefined,
      })
    );
  } catch (error) {
    dispatch(authActions.setAuthenticated({ authenticated: false }));
  }
};

export const refreshToken =
  (payload: { authError: AxiosError; accessToken: string }) =>
  async (dispatch: Dispatch) => {
    try {
      if (
        payload.authError.config.url.match(/\/auth\/(token|signin|signout)/)
      ) {
        throw payload.authError;
      }

      const response = await postToken(payload.accessToken);

      dispatch(
        authActions.setAccessToken({ accessToken: response.token.accessToken })
      );

      return await axios({
        ...payload.authError.config,
        headers: {
          ...payload.authError.config.headers,
          // The Authorization header should always start with an uppercase because otherwise the header will be added twice,
          // which leads to an auth error. This might be an axios bug as axios will produce the authorization header as:
          // authorization: bearer ...;
          Authorization: `bearer ${response.token.accessToken}`,
        },
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message.error({ content: error.message });
      }

      dispatch(authActions.setAccessToken({ accessToken: "" }));
      dispatch(authActions.setAuthenticated({ authenticated: false }));
    }
  };
