import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApplicationProps } from '@parloa/core-e2e-monitoring-api';
import { RootState } from '../store';

interface State {
  pending: boolean;
  applications: ApplicationProps[];
  error?: Error;
}

const initialState: State = {
  pending: false,
  applications: [],
  error: undefined,
};

export const applicationSlice = createSlice({
  name: 'applications-feature',
  initialState,
  reducers: {
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
      state.error = undefined;
    },
    setApplications: (state, action: PayloadAction<{ applications: ApplicationProps[] }>) => {
      state.applications = action.payload.applications;
      state.pending = false;
      state.error = undefined;
    },
    setError: (state, action: PayloadAction<{ error: Error }>) => {
      state.pending = false;
      state.error = action.payload.error;
    },
  },
});

export const applicationsActions = applicationSlice.actions;

export const selectApplicationsScenePending = (state: RootState) => state.applicationsFeature.pending;
export const selectApplications = (state: RootState) => state.applicationsFeature.applications;

export default applicationSlice.reducer;
