import * as Ant from 'antd';
import {
  SyncOutlined,
  PlayCircleOutlined,
  StopOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import SectionEntry from '../../components/section-entry';
import Section from '../../components/section';
import {
  selectApiState,
  selectExecutionState,
  selectNextCheck,
  selectLastCheck,
  selectApplicationInfos,
} from '../../data/slices';
import {
  fetchApiState,
  fetchMonitoringExecutionInformations,
  startMonitoringExecution,
  stopMonitoringExecution,
} from '../../data/thunks';

import ApiState from './components/api-state';
import Connector from './components/connector';
import { useAppDispatch } from '../../data/store';

const Home: React.FunctionComponent = () => {
  const pollingTimeoutId = useRef<number>(null);

  const apiState = useSelector(selectApiState);
  const executionState = useSelector(selectExecutionState);
  const nextCheck = useSelector(selectNextCheck);
  const lastCheck = useSelector(selectLastCheck);

  const app = useSelector(selectApplicationInfos);

  const dispatch = useAppDispatch();

  const refreshMonitoringExecutionInfo = React.useCallback(() => {
    dispatch(fetchMonitoringExecutionInformations());
    // dispatch(fetchSettings());
  }, [dispatch]);

  const refreshApiState = React.useCallback(() => {
    dispatch(fetchApiState());
  }, [dispatch]);

  React.useEffect(() => {
    refreshApiState();
    refreshMonitoringExecutionInfo();
  }, [refreshApiState, refreshMonitoringExecutionInfo]);

  React.useEffect(() => {
    const startPolling = () => {
      refreshMonitoringExecutionInfo();
      pollingTimeoutId.current = setTimeout(startPolling, 5000) as any;
    };

    const stopPolling = () => {
      clearTimeout(pollingTimeoutId.current);
      pollingTimeoutId.current = null;
    };

    if (executionState === 'stopped' || !executionState) {
      stopPolling();
    } else {
      if (!pollingTimeoutId.current) {
        startPolling();
      }
    }

    return () => stopPolling();
  }, [executionState, refreshMonitoringExecutionInfo]);

  const getPercantage = () => {
    return app.responseCheckSuccess
      ? 100
      : app.welcomeMessageSuccess
      ? 66.6
      : app.connectionState !== 'connecting'
      ? 33
      : 0;
  };

  return (
    <>
      <Section title="Monitoring Informations">
        <SectionEntry title="API State:">
          <ApiState state={apiState} />
          <Ant.Button icon={<SyncOutlined />} onClick={refreshApiState} size="small" type="dashed" />
        </SectionEntry>
        <SectionEntry title="Monitoring State:">
          <Ant.Tag
            color={executionState === 'running' ? 'green' : 'default'}
            icon={executionState === 'running' ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />}
          >
            Monitoring {executionState}
          </Ant.Tag>
        </SectionEntry>
        <SectionEntry title="Execution Controls">
          <Ant.Button
            style={{ marginRight: 8 }}
            size="small"
            icon={<PlayCircleOutlined />}
            disabled={executionState === 'running'}
            type={executionState === 'running' ? 'default' : 'primary'}
            onClick={() => {
              dispatch(startMonitoringExecution());
            }}
          >
            Start
          </Ant.Button>
          <Ant.Popconfirm
            title="This will stop the monitoring. Are you sure?"
            okText="Stop"
            disabled={['stopped', 'stopping'].includes(executionState)}
            onConfirm={() => {
              dispatch(stopMonitoringExecution());
            }}
          >
            <Ant.Button
              style={{ marginRight: 8 }}
              size="small"
              icon={<StopOutlined />}
              disabled={['stopped', 'stopping'].includes(executionState)}
              type={executionState === 'running' ? 'dashed' : 'primary'}
            >
              Stop
            </Ant.Button>
          </Ant.Popconfirm>
        </SectionEntry>
        <SectionEntry title="Last Check was executed:">
          <Ant.Typography.Text type="secondary">{lastCheck ? lastCheck : 'never'}</Ant.Typography.Text>
        </SectionEntry>
        <SectionEntry title="Next Check will be executed at">
          <Ant.Typography.Text type="secondary">
            {executionState === 'running' ? nextCheck : 'currently stopped'}
          </Ant.Typography.Text>
        </SectionEntry>
        <SectionEntry title="Checked application is ">
          <Ant.Typography.Text type="secondary">
            {executionState === 'running' && app ? (
              <>
                <span>{app.name}</span> <Ant.Divider type="vertical" />
                <Ant.Progress percent={getPercantage()} steps={3} status={app.hadError ? 'exception' : undefined} />
                <div>{`Connection state: call is ${app.connectionState}`}</div>
              </>
            ) : (
              'none'
            )}
          </Ant.Typography.Text>
        </SectionEntry>
      </Section>
      <Section title="Call as Monitoring Bot">
        <Connector />
      </Section>
    </>
  );
};

export default Home;
