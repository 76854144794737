import { ApplicationProps } from '@parloa/core-e2e-monitoring-api';

import { axios } from './axios';

export const getApplications = async () => {
  try {
    const { data } = await axios.get<ApplicationProps[]>('api/v1/applications');

    return data;
  } catch (error) {
    console.log(error);
  }
};

export const getApplication = async (id: string) => {
  try {
    const { data } = await axios.get(`/api/v1/applications/${id}`);

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const postApplication = async (applicationPost: ApplicationProps) => {
  try {
    const { data } = await axios.post(`/api/v1/applications`, applicationPost);

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const patchApplication = async (applicationPatch: Partial<ApplicationProps>, id: string) => {
  try {
    const { data } = await axios.patch(`/api/v1/applications/${id}`, applicationPatch);

    return data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteApplication = async (id: string) => {
  try {
    await axios.delete(`/api/v1/applications/${id}`);
  } catch (error) {
    console.error(error);
  }
};
