import * as Ant from 'antd';
import { Rule } from 'antd/lib/form';
import { FormListFieldData } from 'antd/lib/form/FormList';
import React from 'react';
import { ParsedNumber, parseNumber } from 'libphonenumber-js';
import PhoneNumberInput from '../phone-number-input';

interface Props {
  field?: FormListFieldData;
  index?: number;
  name?: string | string[];
  label?: React.ReactNode;
  required?: boolean;
  value?: string;
  disabled?: boolean;
  onDelete?(index?: number): void;
  onChangeIsValid?(isValid: boolean): void;
}

const PhoneNumberInputFormItem: React.FunctionComponent<Props> = (props) => {
  const { onChangeIsValid } = props;
  const validator = React.useCallback(
    async (_rule: Rule, value: string) => {
      let result: ParsedNumber | Partial<ParsedNumber>;
      value = value.replace(/\s|\.|-/gm, '');
      try {
        result = parseNumber(`${value}`);

        if (result.phone) {
          onChangeIsValid?.(true);

          return true;
        }
      } catch {
        try {
          result = parseNumber(`${value}`);
          if (result.phone) {
            onChangeIsValid?.(true);

            return true;
          }
        } catch {
          onChangeIsValid?.(false);

          throw new Error('Not a valid phone number');
        }
      }

      if (!!result?.country && !!result?.phone) {
        onChangeIsValid?.(true);

        return true;
      }

      onChangeIsValid?.(false);

      throw new Error('Not a valid phone number');
    },
    [onChangeIsValid]
  );

  return (
    <Ant.Form.Item
      {...props.field}
      label={props.label}
      rules={[
        {
          required: props.required,
          validator,
        },
      ]}
      name={props.name ?? props.index}
    >
      <PhoneNumberInput disabled={props.disabled} onDelete={props.onDelete} index={props.index} />
    </Ant.Form.Item>
  );
};

export default PhoneNumberInputFormItem;
