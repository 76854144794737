import * as Ant from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';

import PhoneNumberInput from '../../../../components/phone-number-input';
import { postConnect } from '../../../../data/api/home';

const Connector: React.FunctionComponent = () => {
  const [form] = useForm();

  const connect = async () => {
    const { mobileNumber, destinationNumber } = form.getFieldsValue();

    await postConnect({ mobileNumber, destinationNumber });
  };
  return (
    <Ant.Form labelAlign={'left'} form={form} wrapperCol={{ span: 6 }} labelCol={{ span: 4 }} onFinish={connect}>
      <Ant.Form.Item name="destinationNumber" required label="Destination">
        <PhoneNumberInput required />
      </Ant.Form.Item>
      <Ant.Form.Item name="mobileNumber" required label="Mobile Number">
        <PhoneNumberInput required />
      </Ant.Form.Item>
      <Ant.Button htmlType="submit">Establish Connection</Ant.Button>
    </Ant.Form>
  );
};
export default Connector;
