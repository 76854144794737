import * as Ant from 'antd';
import { useForm } from 'antd/lib/form/Form';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusSquareOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { isValidNumber, ParsedNumber, parseNumber } from 'libphonenumber-js';
import React, { useState } from 'react';

import DeletableInput from '../../components/deletable-input';
import PhoneNumberFormItem from '../../components/phone-number-form-item';
import {
  LoadTestRequestBody,
  useExecuteLoadTestMutation,
  useGetLoadTestsQuery,
  useDeleteLoadTestMutation,
} from '../../data/api/load-test';
import { useGetSettingsQuery } from '../../data/api/settings';

import ActiveLoadTest from './components/active-load-test';

interface Props {}

const LoadTest: React.FunctionComponent<Props> = (_props) => {
  const [executeLoadTest, { error: executionError }] = useExecuteLoadTestMutation();
  const [deleteLoadTest] = useDeleteLoadTestMutation();

  const { data: loadTests } = useGetLoadTestsQuery(undefined, {
    pollingInterval: 1000,
  });

  const { data: settings } = useGetSettingsQuery(null);

  const [areDefaultBotsAvailable, setAreDefaultBotsAvailable] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [defaultBotsActivated, setDefaultBotsActivated] = useState(false);
  const [connectionService, setCurrentConnectionService] = useState('twilio');

  const [form] = useForm<LoadTestRequestBody>();
  const [title, setTitle] = useState('');

  const performLoadTest = () => {
    form.validateFields();
    const { destination, ...fields } = form.getFieldsValue();
    try {
      const parsed = parseNumber(destination);
      if (!isValidNumber(parsed as ParsedNumber)) {
        throw new Error('Not Valid');
      }
    } catch (error) {
      return Ant.message.error(`${destination} does not seem to be a valid telephone number`);
    }

    executeLoadTest({ ...fields, destination });
  };

  React.useEffect(() => {
    if (settings) {
      const connectionService = form.getFieldsValue().connectionService;
      const defaultBotsAvailable = !!settings.defaultLoadTestingBots[connectionService].length;
      setAreDefaultBotsAvailable(defaultBotsAvailable);
      setDefaultBotsActivated(defaultBotsAvailable);
      form.setFieldsValue({ useDefaultBots: defaultBotsAvailable });
    }
  }, [form, settings]);

  React.useEffect(() => {
    const errorMessage = (executionError as any)?.data?.message;

    if (errorMessage) {
      Ant.message.error(errorMessage);
    }
  }, [executionError]);

  return (
    <>
      <Ant.Typography.Title level={1}>Load Test Area</Ant.Typography.Title>
      <Ant.Typography.Text>Perform a load test against a destination number.</Ant.Typography.Text>
      <Ant.Divider />
      <Ant.Form
        form={form}
        wrapperCol={{ span: 4 }}
        labelCol={{ span: 5 }}
        colon={false}
        onValuesChange={(values) => {
          const _connectionService: 'tenios' | 'twilio' = values.connectionService;

          if (_connectionService) {
            const defaultBotsAvailable = !!settings.defaultLoadTestingBots[_connectionService].length;

            setAreDefaultBotsAvailable(defaultBotsAvailable);
            setDefaultBotsActivated(defaultBotsAvailable);
            setCurrentConnectionService(_connectionService);

            form.setFieldsValue({ useDefaultBots: defaultBotsAvailable });
            form.setFieldsValue({
              delay: _connectionService === 'tenios' ? 0 : 1000,
            });
          }
        }}
      >
        <Ant.Form.Item
          label={
            <Ant.Popover content="The default bots are 5 Phone V2 production pipelines using a very small dialog. The dialog can be found in the monitoring tenant.">
              Use Default Bots
            </Ant.Popover>
          }
          valuePropName="checked"
          name={'useDefaultBots'}
        >
          <Ant.Switch
            disabled={!areDefaultBotsAvailable}
            checkedChildren={<CheckCircleOutlined />}
            unCheckedChildren={<CloseCircleOutlined />}
            onChange={setDefaultBotsActivated}
          />
        </Ant.Form.Item>
        {defaultBotsActivated ? (
          <Ant.Form.Item
            label={
              <span>
                Approx. response duration in s{' '}
                <Ant.Tooltip title="If a value > 0 is set a response will be generated which is send to the tested bot of the destination number. It will count from 0 to 9 for the amount of seconds you insert into the field.">
                  <ExclamationCircleOutlined />
                </Ant.Tooltip>
              </span>
            }
            initialValue={10}
            name="duration"
          >
            <Ant.InputNumber />
          </Ant.Form.Item>
        ) : (
          <Ant.Form.List name="botNumbers">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <Ant.Form.Item {...field} label={`${index + 1}. Bot`} name={index}>
                    {<DeletableInput onDelete={remove} index={index} key={`mbot_${index}`} />}
                  </Ant.Form.Item>
                ))}
                <Ant.Form.Item label=" ">
                  <Ant.Button type="dashed" onClick={() => add('')}>
                    <PlusSquareOutlined /> Add bot number
                  </Ant.Button>
                </Ant.Form.Item>
              </>
            )}
          </Ant.Form.List>
        )}
        <PhoneNumberFormItem
          label="Destination Number"
          name="destination"
          required={true}
          onChangeIsValid={(valid: boolean) => setIsPhoneNumberValid(valid)}
        />
        {connectionService === 'twilio' && (
          <Ant.Form.Item label="Caller Id" initialValue="+493083796501" name="callerId" rules={[{ required: true }]}>
            <Ant.Input />
          </Ant.Form.Item>
        )}
        <Ant.Form.Item label="Call Count" initialValue={1} name="callCount">
          <Ant.InputNumber />
        </Ant.Form.Item>
        <Ant.Form.Item
          label="Delay Between Calls (ms)"
          initialValue={connectionService === 'tenios' ? 0 : 200}
          name="delay"
        >
          <Ant.InputNumber min={connectionService === 'tenios' ? 0 : 200} />
        </Ant.Form.Item>
        <Ant.Form.Item label="Connection Service" name="connectionService" initialValue="twilio">
          <Ant.Radio.Group>
            <Ant.Radio.Button value="twilio" defaultChecked>
              Twilio
            </Ant.Radio.Button>
            <Ant.Radio.Button value="tenios">Tenios</Ant.Radio.Button>
          </Ant.Radio.Group>
        </Ant.Form.Item>
      </Ant.Form>

      {!!loadTests?.length && (
        <>
          <Ant.Typography.Title level={4}>Runnning Load Tests</Ant.Typography.Title>
          {loadTests.map((loadTest) => (
            <ActiveLoadTest key={loadTest.callerId} {...loadTest} onDelete={deleteLoadTest} />
          ))}
        </>
      )}
      <Ant.Divider />
      <Ant.Popconfirm
        disabled={!isPhoneNumberValid}
        destroyTooltipOnHide
        title={title}
        onVisibleChange={() => {
          setTitle(
            `Are you sure you want to trigger a load test with ${
              form.getFieldsValue().callCount
            } calls against ${form.getFieldValue('destination')}?`
          );

          form.validateFields();
        }}
        onConfirm={performLoadTest}
        arrowPointAtCenter
        placement="topLeft"
        okType="danger"
        okText="Yes, I know what I'm doing"
        cancelText="No"
      >
        <Ant.Button style={{ width: 220 }} disabled={!isPhoneNumberValid} htmlType="submit" type="primary">
          <WarningOutlined />
          PERFORM LOAD TEST
          <WarningOutlined />
        </Ant.Button>
      </Ant.Popconfirm>
    </>
  );
};
export default LoadTest;
