import _axios, { AxiosError } from 'axios';

import { store } from '../store';
import { selectAccessToken } from '../slices/auth';
import { refreshToken } from '../thunks';
import { message } from 'antd';

export const axios = _axios.create({});
export const isAxiosError = _axios.isAxiosError;

axios.interceptors.request.use(
  (config) => {
    if (!config.url.includes('/auth/')) {
      config.headers.Authorization = `bearer ${selectAccessToken(store.getState())}`;
    }

    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if ((error.response.data as any)?.message) message.error((error.response.data as any)?.message);

    if (error.response.status === 401) {
      const accessToken = selectAccessToken(store.getState());

      return store.dispatch(refreshToken({ authError: error, accessToken }));
    }

    throw error;
  }
);
