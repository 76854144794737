import { DateTime } from 'luxon';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ExecutionProps } from '@parloa/core-e2e-monitoring-api';

import { RootState } from '../store';
import { ApplicationInfos } from '@parloa/core-e2e-monitoring-api';

interface State {
  apiState: 'online' | 'offline' | 'checking';
  error?: Error;
  lastCheck: string | undefined;
  nextCheck: string | undefined;
  state: 'stopping' | 'stopped' | 'running';
  applicationInfos: ApplicationInfos;
}

const initialState: State = {
  apiState: 'checking',
  error: undefined,
  lastCheck: undefined,
  nextCheck: undefined,
  state: 'stopped',
  applicationInfos: null,
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setMonitoringExecutionInfo: (
      state,
      action: PayloadAction<ExecutionProps & { applicationInfos?: ApplicationInfos }>
    ) => {
      state.lastCheck = DateTime.fromMillis(action.payload.lastCheck ?? 0).toLocaleString(
        DateTime.DATETIME_HUGE_WITH_SECONDS
      );
      state.nextCheck = action.payload.nextCheck
        ? DateTime.fromMillis(action.payload.nextCheck).toLocaleString(DateTime.DATETIME_HUGE_WITH_SECONDS)
        : undefined;
      state.state = action.payload.state;
      state.applicationInfos = action.payload.applicationInfos;
    },
    setApiState: (state, action: PayloadAction<State['apiState']>) => {
      state.apiState = action.payload;
      state.error = undefined;
    },
    setError: (state, action: PayloadAction<{ error: Error }>) => {
      state.error = action.payload.error;
    },
  },
});

export const homeActions = homeSlice.actions;

export const selectApiState = (state: RootState) => state.homeFeature.apiState;
export const selectLastCheck = (state: RootState) => state.homeFeature.lastCheck;
export const selectNextCheck = (state: RootState) => state.homeFeature.nextCheck;
export const selectExecutionState = (state: RootState) => state.homeFeature.state;
export const selectApplicationInfos = (state: RootState) => state.homeFeature.applicationInfos;

export default homeSlice.reducer;
