import { configureStore, Tuple } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/lib/persistStore';
import storage from 'redux-persist/lib/storage';

import { loadTestApi } from './api/load-test';
import { settingsApi } from './api/settings';

import { rootReducer } from './slices';
import { useDispatch } from 'react-redux';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['authFeature'],
};

const reducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddelWare) =>
    getDefaultMiddelWare({ serializableCheck: true }).concat(loadTestApi.middleware, settingsApi.middleware),
  devTools: { shouldHotReload: true },
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>(); // Export a hook that can be reused to resolve types
