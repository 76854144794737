export enum colors {
  // Brand Colors
  brandBlack = '#1D1E23',
  brandPink = '#E80358',
  brandYellow = '#FCB00E',
  brandBlue1 = '#F6F7FF',
  brandBlue2 = '#E2E7FF',
  brandBlue3 = '#B0BEFF',
  brandBlue4 = '#6D86F6',
  brandBlue5 = '#4162F6',
  brandBlue6 = '#1E45F2',
  brandBlue7 = '#1D3DCB',
  brandBlue8 = '#1B35A5',
  brandBlue9 = '#1F3079',
  brandBlue10 = '#192457',

  // Grey Colors
  grey1 = '#FFFFFF',
  grey2 = '#FAFAFA',
  grey3 = '#F5F5F5',
  grey4 = '#F0F0F0',
  grey5 = '#D9D9D9',
  grey6 = '#BFBFBF',
  grey7 = '#8C8C8C',
  grey8 = '#595959',
  grey9 = '#262626',
  grey10 = '#000000',

  // Other Colors
  purple1 = '#9254DE',
  purple2 = '#722ED1',
  purple3 = '#391085',
  purple4 = '#120338',
  magenta1 = '#EB2F96',
  magenta2 = '#C41D7F',
  magenta3 = '#9E1068',
  magenta4 = '#780650',
  geekBlue1 = '#2F54EB',
  geekBlue2 = '#1D39C4',
  geekBlue3 = '#F0F5FF',
  geekBlue4 = '#ADC6FF',
  oceanBlue1 = '#0A76CF',
  oceanBlue2 = '#075CA2',
  turquoise1 = '#08979C',
  turquoise2 = '#006D75',
  mint1 = '#008C51',
  mint2 = '#005732',
  green1 = '#52C41A',
  green2 = '#E8F4E3',
  grassGreen1 = '#84C322',
  grassGreen2 = '#69A20F',
  sunYellow1 = '#FAAD14',
  sunYellow2 = '#CF8504',
  orange1 = '#FA8C16',
  orange2 = '#D46B08',
  volcanoRed1 = '#FA541C',
  volcanoRed2 = '#D4380D',
  dustRed1 = '#CF1322',
  dustRed2 = '#A8071A',
  red1 = '#F5222D',
  red2 = '#F9E3E5',
}
