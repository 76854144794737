import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface State {
  token: string;
  authenticated: boolean;
}

const initialState: State = {
  token: "",
  authenticated: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<{ accessToken: string }>) => {
      state.token = action.payload.accessToken;
    },
    setAuthenticated: (
      state,
      action: PayloadAction<{ authenticated: boolean }>
    ) => {
      state.authenticated = action.payload.authenticated;
    },
  },
});

export const authActions = authSlice.actions;

export const selectAccessToken = (state: RootState) => state.authFeature.token;
export const selectAuthenticated = (state: RootState) =>
  state.authFeature.authenticated;

export default authSlice.reducer;
