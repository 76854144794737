import { Dispatch } from "@reduxjs/toolkit";
import {
  checkApiState,
  getMonitoringExecution,
  postMonitoringExecutionStart,
  postMonitoringExecutionStop,
} from "../api/home";

import { homeActions } from "../slices/home";

export const fetchApiState = () => async (dispatch: Dispatch) => {
  dispatch(homeActions.setApiState("checking"));

  const returnCode = await checkApiState();

  if (returnCode === 200) {
    dispatch(homeActions.setApiState("online"));
  }

  if (returnCode !== 200) {
    dispatch(homeActions.setApiState("offline"));
  }
};

export const fetchMonitoringExecutionInformations =
  () => async (dispatch: Dispatch) => {
    const execution = await getMonitoringExecution();

    if (execution) {
      dispatch(homeActions.setMonitoringExecutionInfo(execution));
    }
  };

export const startMonitoringExecution = () => async (dispatch: Dispatch) => {
  const execution = await postMonitoringExecutionStart();

  if (execution) {
    dispatch(homeActions.setMonitoringExecutionInfo(execution));
  }
};

export const stopMonitoringExecution = () => async (dispatch: Dispatch) => {
  const response = await postMonitoringExecutionStop();

  if (response) {
    dispatch(
      homeActions.setMonitoringExecutionInfo({
        ...response,
        applicationInfos: null,
      })
    );
  }
};
