import * as Ant from 'antd';
import React from 'react';

import { CloseSquareOutlined } from '@ant-design/icons';

interface Props extends Ant.InputProps {
  index: number;
  onDelete(index: number): void;
}

const DeletableInput: React.FunctionComponent<Props> = ({ onDelete, index, ...props }) => (
  <Ant.Input
    {...props}
    suffix={<CloseSquareOutlined onClick={() => onDelete(index)} style={{ cursor: 'pointer' }} />}
  />
);

export default DeletableInput;
