import React from 'react';
import { Layout } from 'antd';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Header from './components/header';
import Footer from './components/footer';
import { selectAuthenticated } from './data/slices/auth';
import Home from './scenes/home';
import NotFound from './scenes/not-found';
import Settings from './scenes/settings';
import LoadTest from './scenes/load-test';
import Forbidden from './scenes/forbidden';
import MonitoredApplications from './scenes/monitored-applications';

export const App: React.FunctionComponent = () => {
  const authenticated = useSelector(selectAuthenticated);

  return (
    <div style={{ position: 'absolute', display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      {authenticated ? (
        <>
          <Header renderMenu />
          <div style={{ display: 'flex', flex: 1, padding: 24, backgroundColor: '#e9e9e9', overflow: 'auto' }}>
            <Layout.Content>
              <div
                style={{
                  backgroundColor: 'white',
                  flex: 1,
                  display: 'flex',
                  padding: 24,
                  flexDirection: 'column',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <Switch>
                  <Redirect exact from="/" to="/home" />
                  <Route path="/home" component={Home} />
                  <Route path="/settings" component={Settings} />
                  <Route path="/load-test" component={LoadTest} />
                  <Route path="/monitored-applications" component={MonitoredApplications} />
                  <Route component={NotFound} />
                </Switch>
              </div>
            </Layout.Content>
          </div>
          <Footer />
        </>
      ) : (
        <Switch>
          <Redirect from="/:section" to="/" />
          <Route component={Forbidden} />
        </Switch>
      )}
    </div>
  );
};
