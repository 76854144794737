import * as Ant from 'antd';
import React from 'react';

import PhoneNumberFormItem from '../../../../components/phone-number-form-item';

import WeekDayChecker from './components/week-day-checker';
import { useSettings } from '../../../../data/api/settings';

interface Props {
  form: Ant.FormInstance;
}

const ModalContent: React.FunctionComponent<Props> = (props) => {
  const { settings, isFetching } = useSettings();

  return (
    <Ant.Card>
      <Ant.Form form={props.form} labelCol={{ span: 4 }}>
        <Ant.Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Ant.Input />
        </Ant.Form.Item>
        <PhoneNumberFormItem label="Tel. Number" name="number" required />
        <Ant.Form.Item label="Description" name="description">
          <Ant.Input />
        </Ant.Form.Item>
        <Ant.Form.Item label="Tenant" name="tenant">
          <Ant.Input />
        </Ant.Form.Item>
        <Ant.Form.Item label="Parloa Project URL" name="parloaUrl">
          <Ant.Input />
        </Ant.Form.Item>
        <Ant.Form.Item label="Test between" name="timeFrame">
          <Ant.TimePicker.RangePicker format="HH:mm:ss" order={false} />
        </Ant.Form.Item>
        <Ant.Form.Item
          label="Check on weekday"
          name="weekdays"
          initialValue={props.form.getFieldValue('weekdays') ?? [1, 2, 3, 4, 5, 6, 7]}
        >
          <WeekDayChecker />
        </Ant.Form.Item>
        <Ant.Form.Item
          initialValue={props.form.getFieldValue('active') ?? true}
          label="Active"
          name="active"
          valuePropName="checked"
        >
          <Ant.Switch />
        </Ant.Form.Item>
        <Ant.Form.Item initialValue={props.form.getFieldValue('language') ?? 'de'} label="Language" name="language">
          <Ant.Select disabled={isFetching}>
            {settings?.monitoringSettings?.configurations?.map(({ locale }) => (
              <Ant.Select.Option value={locale}>{locale}</Ant.Select.Option>
            ))}
          </Ant.Select>
        </Ant.Form.Item>
      </Ant.Form>
    </Ant.Card>
  );
};
export default ModalContent;
