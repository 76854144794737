import * as Ant from 'antd';
import React from 'react';

interface Props {
  title: string;
  children?: React.ReactNode;
}

const Section: React.FunctionComponent<Props> = (props) => (
  <div style={{ padding: 8 }}>
    <Ant.Typography.Title level={3}>{props.title}</Ant.Typography.Title>
    <Ant.Card>{props.children}</Ant.Card>
  </div>
);

export default Section;
