import { message } from 'antd';
import { ApplicationProps, ExecutionProps } from '@parloa/core-e2e-monitoring-api';
import { axios, isAxiosError } from './axios';

export const checkApiState = async () => {
  try {
    const { status } = await axios.get('/api/v1/status');

    return status;
  } catch (error) {
    console.log(error);

    if (isAxiosError(error)) {
      return error?.response?.status ?? 500;
    }
  }
};

export const postMonitoringExecutionStart = async () => {
  try {
    const { data } = await axios.post<ExecutionProps>('/api/v1/services/execution/start');

    return data;
  } catch (error) {
    console.error(error);

    if (isAxiosError(error)) {
      message.error(error.response.statusText);
    }
  }
};

export const postMonitoringExecutionStop = async () => {
  try {
    const { data } = await axios.post<ExecutionProps>('/api/v1/services/execution/stop');

    return data;
  } catch (error) {
    console.error(error);

    if (isAxiosError(error)) {
      message.error(error.response.statusText);
    }
  }
};

export const getMonitoringExecution = async () => {
  try {
    const { data } = await axios.get<ExecutionProps & { currentApplication: ApplicationProps }>(
      '/api/v1/services/execution'
    );

    return data;
  } catch (error) {
    console.log(error);

    if (isAxiosError(error)) {
      message.error(error.response.statusText);
    }
  }
};

export const postConnect = async (request: { destinationNumber: string; mobileNumber: string }) => {
  try {
    await axios.post('/api/v1/services/connect', request);
  } catch (error) {
    console.log(error);

    if (isAxiosError(error)) {
      message.error(error.response.statusText);
    }
  }
};
