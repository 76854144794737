import * as Ant from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import React from "react";

interface Props {
  state: "online" | "offline" | "checking";
}

const getTagProps = (state: Props["state"]) => {
  switch (state) {
    case "online":
      return { color: "success", icon: <CheckCircleOutlined /> };
    case "checking":
      return { color: "processing", icon: <SyncOutlined spin /> };
    case "offline":
      return { color: "error", icon: <CloseCircleOutlined spin /> };
  }
};

const ApiState: React.FunctionComponent<Props> = (props) => (
  <Ant.Tag {...getTagProps(props.state)}>{props.state}</Ant.Tag>
);

export default ApiState;
