import * as Ant from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';

import { ApplicationProps } from '@parloa/core-e2e-monitoring-api';

import ModalContent from '../modal-content';

interface Props {
  visible: boolean;
  onConfirm(application: ApplicationProps): void;
  onCancel(): void;
}

const CreateApplicationContent: React.FunctionComponent<Props> = (props) => {
  const [form] = useForm();

  const onOk = async () => {
    try {
      await form.validateFields();

      const values = {
        ...form.getFieldsValue(),
        number: (form.getFieldValue('number') as string)?.replace(/\s/g, ''),
        weekdays: form.getFieldValue('weekdays'),
      };

      props.onConfirm({
        ...values,
        timeFrame: values.timeFrame
          ? {
              start: values.timeFrame[0].format('HH:mm:ss'),
              end: values.timeFrame[1].format('HH:mm:ss'),
            }
          : undefined,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Ant.Modal
      maskClosable={false}
      visible={props.visible}
      okText="Create"
      onOk={onOk}
      onCancel={props.onCancel}
      afterClose={form.resetFields}
      title="Create New Monitoring Instance"
      destroyOnClose
      width="50%"
    >
      <ModalContent form={form} />
    </Ant.Modal>
  );
};

export default CreateApplicationContent;
