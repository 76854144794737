import { combineReducers } from "redux";

import applicationsFeature from "./applications";
import authFeature from "./auth";
import homeFeature from "./home";
import loadTestFeature from "./load-test";
import { loadTestApi } from "../api/load-test";
import { settingsApi } from "../api/settings";

export * from "./applications";
export * from "./auth";
export * from "./home";
export * from "./load-test";

export const rootReducer = combineReducers({
  [loadTestApi.reducerPath]: loadTestApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  applicationsFeature,
  homeFeature,
  authFeature,
  loadTestFeature,
});
