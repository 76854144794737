import { Layout, Typography } from 'antd';
import { LaptopOutlined } from '@ant-design/icons';

const Footer = () => (
  <Layout.Footer style={{ backgroundColor: 'white' }}>
    <Typography.Text>
      Made with <LaptopOutlined /> by Parloa
    </Typography.Text>
  </Layout.Footer>
);

export default Footer;
